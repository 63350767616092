import React, { Suspense, useReducer, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from 'use-reducer-logger';

import PrivateRoute from './routes/PrivateRoute';
import Loader from './components/Loaders/Loader';
import UserContext from './contexts/userContext';
import userReducer from './reducers/userReducer';
import styles from './app.module.scss';

const LogIn = React.lazy(() => import('./pages/LogIn'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Dashboard = React.lazy(() => import('./routes/dashBoardRoutes'));

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
});

function App() {
  const initialStateUser = useContext(UserContext);
  const [stateUser, dispatchUser] = useReducer(
    logger(userReducer),
    initialStateUser,
  );

  return (
    <MuiThemeProvider theme={theme}>
      <UserContext.Provider value={{ stateUser, dispatchUser }}>
        <div className={styles.app}>
          <ToastContainer />

          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/log-in">
                <LogIn />
              </Route>

              <PrivateRoute path="/">
                <Dashboard />
              </PrivateRoute>

              <Route exact path="/404">
                <NotFound />
              </Route>

              <Redirect from="*" to="/404" />
              
            </Switch>
          </Suspense>
        </div>
      </UserContext.Provider>
    </MuiThemeProvider>
  );
}

export default App;
