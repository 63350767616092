/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL } from './api.config';

const instance = axios.create({
  baseURL: API_URL,
});

export const setupInterceptors = (history) => {
  instance.interceptors.request.use((config) => {
    const { accessToken } = JSON.parse(localStorage.getItem('authHausListAd') || '{}');
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
    return config;
  });

  instance.interceptors.response.use(
    (response) => response.data,
    (err) => {
      const errorAuth = err?.response?.status === 401;

      const errorMessageBackEnd = err?.response?.status === 400 && err.response.data.errors;

      const errorMessage = err?.response?.data?.message
        ? err?.response?.data?.message
        : err.message;

      if (errorMessageBackEnd) {
        return Promise.reject(err.response.data.errors);
      }
      if (errorAuth) {
        localStorage.removeItem('authHausListAd');
        history.push('/log-in');
        delete axios.defaults.headers.common.Authorization;
        return false;
      }
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    },
  );
};

export default instance;
