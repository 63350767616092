/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { isAuthenticated } from '../helpers/axiosActionsWithToken';
import ErrorBoundary from '../components/ErrorBoundary';

const PrivateRoute = ({ children, ...rest }) => {
  const { pathname } = useLocation();
  
  return (
    <ErrorBoundary key={pathname}>
      <Route
        {...rest}
        render={({ location }) => (isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/log-in',
              state: { from: location },
            }}
          />
        ))}
      />
    </ErrorBoundary>
  );
};

export default PrivateRoute;
