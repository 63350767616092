import { SET_USER_INFO, RESET_USER_INFO } from './actionTypes';
import { initialState } from '../../contexts/userContext';

export default function reducer(state, action) {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state, ...action.payload,
      };
    }
    case RESET_USER_INFO:
      return initialState;

    default:
      return state;
  }
}
