import axios from '../api/instance';

export const getSavedAuthData = () => JSON.parse(localStorage.getItem('authHausListAd') || '{}');

export const saveAuthData = (data) => localStorage.setItem('authHausListAd', JSON.stringify(data));

export const saveTokens = (axiosData) => {
  const { accessToken, user: { id: userId } } = axiosData;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  saveAuthData({ userId, accessToken });
  return Promise.resolve(axiosData);
};

export const isAuthenticated = () => !!getSavedAuthData().accessToken;

export const removeAuthData = () => {
  localStorage.removeItem('authHausListAd');
  delete axios.defaults.headers.common.Authorization;
}; 


