const API_URL = 'https://staging-api.hauslist.co.uk';

const ENDPOINTS = {
  login: './auth/login',
  properties: './admin/properties',
  users: './admin/users',
  conveyancers: './admin/conveyancers',
  documents: './admin/documents',
  faq: './admin/faq',
};

export { API_URL, ENDPOINTS };
